<template>
  <header>
    <the-main-nav></the-main-nav>
  </header>
  <section class="mainSection">
    <div class="content">
      <h1>FAQ</h1>
      <h2>How much will Agni cost me?</h2>
      <p>
        Agni is completely free-of-charge. We are, however, considering to implement a “premium” functionality in the future.
      </p>
      <h2>How do I install Agni?</h2>
      <ol>
        <li>Upload the Agni plugin file to your blog and activate it</li>
        <li>Click the 'Settings' option link, verify the settings and
          submit</li>
          <li>Check your email for a registration validation email containing a
          registration token</li>
          <li>Enter the registration token on the settings page and click
          submit</li>
      </ol>
      <p>
          That's it. Agni will now regularly test your login page and report
          any findings.
      </p>
      <div>
        <h2>What if I do not want to use Agni anymore?</h2>
        <p>Once your account has been set up and is active, you can turn off testing anytime by disabling the 'Scheduled Test' option.<br /></p>
        <p>You can remove your account at any time by simply removing Agni, which will cause the plugin to delete the account information stored on agni.rocks. </p>
        <p>Alternatively, you may request your account to be removed by contacting us via the button at the bottom of the page.</p>
      </div>
      <h1>Cockpit</h1>
      <h2>How do I log in?</h2>
      <p>There is no login functionality implemented as of yet. User can access the cockpit through a specific link provided via email.</p>
      <h2>Is it possible to switch between multiple sites?</h2>
      <p>This feature is yet to be implemented. For now, users may access each site through a link provided via email.</p>
      <h2>How does the rating works?</h2>
      <p>Agni measures the average duration of the test and the failure ratio of all executed tests on the website. The result are featured in the form of percentile score—compared to results stored in our databases. The rocket icon visualizes the average duration. </p>
      <h1>Plugin</h1>
      <h2>
        What data is exchanged between my WordPress site and the agni.rocks service?
      </h2>
      <p>
        Please see our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms">Terms of Use</a> for more information.<br />
      </p>
      <h2>How often is Agni reporting the test results?</h2>
      <p>Typically, once a day. This can modified in the plugin settings.</p>
      <h2>Can I run the test multiple times per day?</h2>
      <p>
        This feature will be implemented in the near future. Currently, all tests are run once per day.
      </p>
      <h2>Can I extend the tests to cover other pages?</h2>
      <p>
        We're still working on implementing more testing and monitoring functionalities for Agni. Be sure to check agni.rocks regularly to stay updated.<br />
      </p>
      <h1>General Information</h1>
      <h2>Can I disable the tests without uninstalling?</h2>
      <p>
        You may easily disable and enable the tests without the need to uninstall Agni, using the 'Schedule Tests' feature.
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheMainNav from '@/components/layout/TheMainNav.vue';

export default defineComponent({
  components: {
    TheMainNav,
  },
});
</script>

<style lang="scss" scoped>
* {
  font-family: "pm";
  display: block;
  font-size: 13px;

  @media (max-width: 500px) {
    font-size: 11px;
  }
}
.mainSection {
  padding: 100px 0;
}
h1 {
  display: block;
  color: #5828ff;
  font-size: 4em;
  font-family: "pm-b";
  margin-top: 50px;
  margin-bottom: 25px;
}
h2 {
  color: #5828ff;
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.5em;
}
i {
  color: #747474;
  font-size: 1.7em;
  max-width: 800px;
}
p{
  font-size: 18px;
}

ol {
  list-style: decimal;
  color: #747474;
  max-width: 900px;
  margin-top: 30px;

  li {
    display: list-item;
    font-size: 18px;
    margin: 10px 0px 10px 45px;
  }
}
</style>
